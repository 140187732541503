import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
  width: 100%;
  margin: 9px 0;

  .add_to_GenG {
    border-radius: 6px;
  }
`;
const SponsorAD = () => {
    const country = useSelector((state) => state?.imageStore.country);


    // <a href={'https://bit.ly/3XYhema'} target={'_blank'}>
    //     <img src={'./images/ad_geng_kr.jpg'} width={'100%'} height={50} alt={''}
    //          className={'add_to_Gen'}/>
    // </a>

    return (
        <Wrapper>
            {country === 'KR' ?
                <a href={'https://bit.ly/3XNSr2f'} target={'_blank'}>
                    <img src={'./images/ad_geng_kr.png'} width={'100%'} height={50} alt={''}
                         className={'add_to_Gen'}/>
                </a>
                :
                <a href={'https://forms.gle/NYMLvh77mSW6eBscA'} target={'_blank'}>
                    <img src={'./images/ad_geng_en.png'} width={'100%'} height={50}
                         className={'add_to_Gen_Global'} alt={''}/>
                </a>
            }
        </Wrapper>
    );
};

export default SponsorAD;