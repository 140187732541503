import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import _ from "lodash";
import {useLocation} from "react-router-dom";

const SummonerAdBanner = ({status}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const country = useSelector((state) => state?.imageStore.country);
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        setImgError(false)
    }, [currentLang]);


    if (imgError) {
        return (
            <div>
                {country === 'KR' ?
                    <KoreanAd/>
                    :
                    <a href={'https://forms.gle/NYMLvh77mSW6eBscA'} target={'_blank'}>
                        <img src={'./images/ad_geng_en.png'} width={'100%'} height={50}
                             className={'add_to_Gen_Global'} alt={''}/>
                    </a>
                }
            </div>

        )
    }

    if (!status?.title_url || status?.title_url === '-') {
        return (
            <div>
                {country === 'KR' ?
                    <KoreanAd/>
                    :
                    <a href={'https://forms.gle/NYMLvh77mSW6eBscA'} target={'_blank'}>
                        <img src={'./images/ad_geng_en.png'} width={'100%'} height={50}
                             className={'add_to_Gen_Global'} alt={''}/>
                    </a>
                }
            </div>

        )
    }

    return (
        <div>
            <a href={status.title_url} target={'_blank'} rel={'noreferrer'}>
                <img
                    src={`${process.env.REACT_APP_ROOT_IMG}sub_title/${status?.name}_${currentLang === 'KOR' ? 'kr' : 'en'}_title_1.png`}
                    width={'100%'}
                    height={50} alt={''}
                    className={'sub_title_banner'}
                    onError={() => {
                        setImgError(true);
                    }}
                />
            </a>
        </div>
    );
};


const KoreanAd = () => {
    const location = useLocation();

    const setAd = (value) => {
        localStorage.setItem('summonerBanner', String(value))
        setAdState(String(value));
    }

    const [adState,setAdState] = useState(() => {
        const index = localStorage.getItem('summonerBanner');
        if (!index) {
            const random = _.random(1, 2);
            setAd(random)
            return String(random)
        }
        return index;
    });





    useEffect(() => {
        if (adState === '1') {
            setAd(2)
        } else if (adState === '2') {
            setAd(1)
        }
    }, [location]);




    if (adState === '1') {
        return (
            <iframe
                src="https://ads-partners.coupang.com/widgets.html?id=798384&template=carousel&trackingCode=AF1158950&subId=&width=1080&height=50&tsource="
                width="1080" height="50" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"
                browsingtopics></iframe>
        )
    } else {
        return (
            <a href={'https://bit.ly/3XNSr2f'} target={'_blank'}>
                <img src={'./images/ad_geng_kr.png'} width={'100%'} height={52} alt={''}
                     className={'add_to_Gen'}/>
            </a>
        )
    }
}

export default SummonerAdBanner;