import React, {useCallback, useMemo} from 'react';
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";
import {ImageSpell} from "../../../../common";
import ChampionDetailRuneImages
    from "../../../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailRunes/ChampionDetailRuneImages";
import InfluInfo from "../../../../summoner/Profile/InfluInfo";
import TierTag from "../../../../common/TierTag";
import {onStorePuuId, threedigits} from "../../../../../function";
import FilledBar from "../../../../common/FilledBar";
import ScoreWrapper from "../../../../summoner/DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import SummonerTags from "../../../../summoner/SummonerTags";
import OpenPlayerInfo from "../openPlayerInfo";
import styled, {useTheme} from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import KDAText from "../../../../common/KDAText";
import {
    AI_SCORE_BAD_TEXT,
    AI_SCORE_GOOD_TEXT,
    AI_SCORE_PERFECT_TEXT,
    AI_SCORE_VERY_BAD_TEXT
} from "../../../../../styled/scoreColor";

const BodyTr = styled.tr`
  background-color: ${props => {
    let color = props.theme.color.site_main_color
    if (props.selected) color = props.theme.color.white_200;
    return color
  }};
  height: 41px;
  border-bottom: ${props => {
    let bottom = 0;
    if (props.borderBottom) bottom = `1px solid ${props.theme.color.gray_200}`;
    if (props.open) bottom = `1px solid ${props.theme.color.default_border_color}`;
    return bottom;
  }};

  &:hover {
    cursor: ${props => props.pointer};
    background-color: ${props => props.theme.color.white_200};
  }
`;

const Bar = styled.div`
  background-color: ${props => props.bgColor};
  height: 48px;
  width: 5px;
  margin-right: 7px;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  .imgChamp {
    width: 40px;
    aspect-ratio: 1;
  }

  .imgDiv {
    border-radius: 7px;
  }

`;

const RuneWrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: 4px;
`;

const FlexBox = styled.div`
  width: ${props => props.width};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => {
    let content = 'flex-start';
    if (props.justifyContent) content = props.justifyContent;
    return content;
  }};
  height: ${props => {
    let height = '100%';
    if (props.height) height = props.height;
    return height;
  }};
  padding: ${props => {
    let padding = 0;
    if (props.padding) padding = props.padding;
    return padding;
  }};
  background-color: ${props => {
    let color = 'transparent';
    if (props.test) color = 'red';
    return color;
  }};
`;

const ColumnBox = styled.div`
  background-color: ${props => {
    let color = 'transparent';
    if (props.test) color = 'red';
    return color;
  }};
  display: flex;
  flex-direction: column;
  justify-content: ${props => {
    let content = 'center';
    if (props.justifyContent) content = props.justifyContent;
    return content;
  }};
  align-items: ${props => {
    let content = 'flex-start';
    if (props.alignItems) content = props.alignItems;
    return content;
  }};
  padding: ${props => {
    let padding = 0;
    if (props.padding) padding = props.padding;
    return padding;
  }};
  margin: ${props => {
    let margin = 0;
    if (props.margin) margin = props.margin;
    return margin;
  }};
  height: 100%;
`;

const ColumnFlexOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const BodyTd = styled.td`
  vertical-align: middle;
  height: 48px;
`;

const EndBodyTd = styled(BodyTd)`
  border-left: 1px solid rgba(0, 0, 0, 0.07);
`;

const SpellWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 34px;

  .imgSpell {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: -8px;
  z-index: 1;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameText = styled.span`
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 2px;
  max-width: 17ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.color.dark_100};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TagText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  margin-left: 3px;
`;

const TierAndInfluWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3px;

`;

const LPText = styled.span`
  line-height: 14px;
  margin-left: 5px;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
`;


const RankWinRateText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.color};
  margin-bottom: 3px;
  width: 100%;
`;

const RankingPercent = styled.span`
  margin-left: 2px;
  margin-right: 3px;
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
`;

const GameText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
  font-weight: 500;
`;


const GameTextWidth = styled(GameText)`
  width: ${props => {
    let lang = '55%';

    if (props.lang !== 'KOR') {
      lang = '63%';
    }
    return lang;
  }};
  font-weight: 400;
`;

const TableText = styled.span`
  font-size: 12px;
  max-width: 11ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.color.dark_100};
`;

const OverflowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 150px;
`;

const MoreWrapper = styled.div`
  ${props => {
    if (props.open) {
      return "transform: rotate(180deg)";
    }
    return "";
  }};
`;

const BackButtonImg = styled.img`
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
const StarSpan = styled.span`
  font-size: 16px;
  color: #FF7A14;
`;

const PromotionCircle = styled.div`
  background-color: #C0C9D1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 6px;
  z-index: 1;
  background-size: 12px 12px;
  background: ${props => {
    let url = "#C0C9D1";
    if (props.data === 'L') url = 'url(/images/icon_tier_lose.svg)';
    if (props.data === 'W') url = 'url(/images/icon_tier_win.svg)';
    return url;
  }};
`;

const PromotionCircleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 4px;
`;

const AbsoluteBar = styled.div`
  position: absolute;
  width: calc(100% - 8px);
  height: 1px;
  top: 45%;
  background-color: #C0C9D1;
`;


const MAX_TAGLIST_WIDTH = 130;


const IngamePlayerTableTr = ({
                                 tag,
                                 enemyChampionId,
                                 summonerInfo,
                                 teamColor,
                                 champion_id,
                                 influData,
                                 spellIds,
                                 summonerName,
                                 puuId,
                                 region,
                                 runeObj,
                                 tier2022,
                                 tier2023,
                                 leaguePoints,
                                 aiScore,
                                 championInfo,
                                 open,
                                 onClickMore,
                                 rank
                             }) => {
    const runes = useSelector((state) => state.imageStore.runes);
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const theme = useTheme();

    const tier2023WinRate = useMemo(() => {
        try {
            const games = tier2023.losses + tier2023.wins;
            if (games > 0) {
                return {
                    winRate: (tier2023.wins / games * 100).toFixed(0),
                    games: games,
                };
            }
        } catch (e) {
            console.log(e);
        }

        return {
            winRate: 0,
            games: 0,
        }

    }, []);


    const onClickMoreButton = useCallback(() => {
        onClickMore(summonerInfo.summoner_id);
    }, []);

    const findCategoryRune = useCallback((runeData, findValue) => {
        return runeData.find((data) => data.id === findValue);
    }, []);

    const findChildRune = useCallback((categoryRune = [], findValue) => {
        return categoryRune.find((data) => {
            if (data.id === findValue) {
                return true;
            } else if (Array.isArray(data.runes)) {
                return findCategoryRune(data.runes, findValue);
            }
        });
    }, [findCategoryRune]);


    const findParentRune = useCallback((categoryRune = [], findValue) => {
        try {
            if (typeof findValue === 'number') {
                return categoryRune.find((data) => {
                    if (data.id === findValue) {
                        return true;
                    } else {
                        return findChildRune(data.slots, findValue);
                    }
                });
            }
            return null;
        } catch (e) {
            return [];
        }

    }, [findChildRune]);

    const getRune = useCallback((runeId) => {
        let runeData = null;
        if (typeof runeId === 'number') {
            for (const rune of runes) {
                if (runes.id === runeId) {
                    runeData = rune;
                } else {
                    rune.slots.forEach((data) => {
                        const finded = data.runes.find((data) => data.id === runeId);
                        if (finded) {
                            runeData = {...finded}
                        }
                    })
                }

                if (runeData !== null) {
                    break;
                }
            }
        }
        return runeData;
    }, [runes]);

    const onClickSummonerName = useCallback((e) => {
        e.stopPropagation();
        onStorePuuId(`${summonerName}-${tag}`, region, puuId);
    }, []);

    const getKda = useCallback((kda) => {
        if (kda === 0) return 0;
        return kda.toFixed(2);
    }, []);

    const get2023WinColor = (winRate) => {
        let color = AI_SCORE_PERFECT_TEXT;
        const value = Number(winRate);
        if (value > 69) {
            color = AI_SCORE_PERFECT_TEXT;
        } else if (value > 59) {
            color = AI_SCORE_GOOD_TEXT;
        } else if (value > 49) {
            color = AI_SCORE_BAD_TEXT;
        } else if (value > 39) {
            color = AI_SCORE_VERY_BAD_TEXT;
        }
        return color;
    }


    const primaryRune = useMemo(() => getRune(Number(runeObj.perk_0)), [runeObj.perk_0]);
    const subRune = useMemo(() => findParentRune(runes, runeObj.perk_4), [runes, runeObj.perk_sub_style]);

    return (
        <>
            <BodyTr onClick={onClickMoreButton} pointer={'pointer'} borderBottom selected={open} open={open}>
                <BodyTd>
                    <FlexBox>
                        <Bar bgColor={teamColor}/>
                        <ImageWrapper>
                            <ChampionImageWithLane champion_id={champion_id}/>
                        </ImageWrapper>
                        <SpellWrapper>
                            <ImageSpell spellId={spellIds.spell_1}/>
                            <ImageSpell spellId={spellIds.spell_2}/>
                        </SpellWrapper>
                        <RuneWrapper>
                            <ChampionDetailRuneImages
                                imageBorder={0}
                                borderColor={'transparent'}
                                disableTooltip={true}
                                width={34}
                                height={34}
                                image={primaryRune?.icon}
                                tooltip={`<span style="color : rgb(23, 199, 145); font-weight: 500">${primaryRune?.name}</span>\n${primaryRune?.shortDesc}`}
                            />
                            <AbsoluteWrapper>
                                <ChampionDetailRuneImages
                                    imageBorder={2}
                                    disableTooltip={true}
                                    borderColor={'transparent'}
                                    width={20}
                                    height={20}
                                    image={subRune?.icon}
                                    tooltip={`<span style="color : rgb(23, 199, 145); font-weight: 500">${subRune?.name}</span>`}
                                />
                            </AbsoluteWrapper>
                        </RuneWrapper>
                        <NameWrapper>
                            <TagWrapper>
                                <NameText onClick={onClickSummonerName}>{summonerName}</NameText>
                                <TagText>#{tag}</TagText>
                            </TagWrapper>
                            {influData.status &&
                                <TierAndInfluWrapper>
                                    <InfluInfo
                                        maxWidthText={83}
                                        showLinks={false}
                                        influData={{...influData, team: influData?.pro_team_al}}
                                    />
                                </TierAndInfluWrapper>}
                        </NameWrapper>
                    </FlexBox>
                </BodyTd>
                <BodyTd>
                    <ColumnBox alignItems={'flex-start'}>
                        <FlexBox width={'100%'} height={'auto'}>
                            <FlexBox width={'40%'} justifyContent={'flex-end'}>
                                <TierTag
                                    tier={tier2023.tier}
                                    division={tier2023.division}
                                />
                            </FlexBox>
                            <LPText>{threedigits(leaguePoints)} LP</LPText>
                        </FlexBox>

                    </ColumnBox>
                </BodyTd>
                <BodyTd>
                    <ColumnBox alignItems={'center'}>
                        <FlexBox height={'auto'} justifyContent={'flex-start'} width={'100%'}>
                            <RankWinRateText
                                color={get2023WinColor(tier2023WinRate.winRate)}>{tier2023WinRate.winRate}<RankingPercent>%</RankingPercent><GameTextWidth
                                lang={currentLang}>({threedigits(tier2023WinRate.games)} {t("ingame.games")})</GameTextWidth></RankWinRateText>
                        </FlexBox>
                        <FlexBox width={'100px'} height={'auto'}>
                            <FilledBar
                                reverse={true}
                                bgColor={theme.color.gray_100}
                                percent={tier2023WinRate.winRate}
                                barColor={get2023WinColor(tier2023WinRate.winRate)}
                                barHeight={4}
                            />
                        </FlexBox>
                    </ColumnBox>
                </BodyTd>
                <BodyTd>
                    <FlexBox justifyContent={'center'} width={'100%'}>
                        <ScoreWrapper
                            fontSize={16}
                            paddingHorizontal={8}
                            noneClickable={true}
                            score={aiScore}
                        />
                    </FlexBox>
                </BodyTd>
                <BodyTd>
                    <FlexBox justifyContent={'space-between'} padding={'8px 22px'}>
                        <ColumnFlexOne justifyContent={'space-between'}>
                            <FlexBox justifyContent={'center'} width={'100%'}>
                                <TableText>{(championInfo.win_rate).toFixed(0)}<GameText>%</GameText></TableText>
                            </FlexBox>
                            <FlexBox justifyContent={'center'}>
                                <GameText>({threedigits(championInfo.games)} {t("ingame.games")})</GameText>
                            </FlexBox>
                        </ColumnFlexOne>

                        <FlexBox width={'62%'} justifyContent={'flex-end'}>
                            <ColumnBox alignItems={'flex-end'} justifyContent={'space-between'}>
                                <KDAText fontSize={'12px'}
                                         value={getKda(championInfo.kda)}>{getKda(championInfo.kda)}</KDAText>
                                <TableText>{championInfo.mastery > 999999 ?
                                    <StarSpan>*</StarSpan> : ""}{threedigits(championInfo.mastery)}</TableText>
                            </ColumnBox>
                            <ColumnBox margin={'0 0 0 5px'} justifyContent={'space-between'}>
                                <GameText>KDA</GameText>
                                <GameText> {t("ingame.mastery")}</GameText>
                            </ColumnBox>
                        </FlexBox>
                    </FlexBox>
                </BodyTd>
                <BodyTd>
                    <ColumnBox alignItems={'center'}>
                        <TierTag
                            tier={tier2022?.tier}
                            division={tier2022?.division}
                        />
                    </ColumnBox>
                </BodyTd>
                <BodyTd>
                    <OverflowDiv>
                        <SummonerTags
                            overflowWidth={MAX_TAGLIST_WIDTH}
                            data={summonerInfo}
                            ranks={rank}
                        />
                    </OverflowDiv>
                </BodyTd>
                <EndBodyTd>
                    <FlexBox justifyContent={'center'}>
                        <MoreWrapper open={open}>
                            <BackButtonImg draggable={false} src={`${theme.icon.path}/icn_expand_open.svg`} alt={""}
                                           width={14}
                                           height={14}/>
                        </MoreWrapper>
                    </FlexBox>
                </EndBodyTd>
            </BodyTr>
            {open &&
                <BodyTr open={open}>
                    <OpenPlayerInfo
                        enemyChampionId={enemyChampionId}
                        data={summonerInfo}
                    />
                </BodyTr>}
        </>
    );
};


const isEquals = (prev, next) => {
    return prev.puuId === next.puuId && prev.open === next.open;
}
export default React.memo(IngamePlayerTableTr, isEquals);
