import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

import {translation} from '../../store/modules/translation';
import {_NOTICE} from "../../store/modules/notice";
import styled, {useTheme} from "styled-components";
import {Link, NavLink, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {getRegion} from "../../function";
import {PREV_RANKING_LANE} from "../../store/reducer/champions";
import {getOriginalNames} from "../../i18n/languages";
import {LANGUAGE_FOR_OS} from "../../i18n/types";
import ThemeSwitch from "./ThemeSwitch";
import regionList from "../../store/static_json/regionList";
import DownArrow from "./TagWithArrow/DownArrow";
import HeaderSearch from "../Header/HeaderSearch";
import UpArrow from "./TagWithArrow/UpArrow";
import ContentSwitch from "./ContectSwitch";
import ProRequestSwitch from "./ProRequestSwitch";


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 32px;
`;
const LogoImg = styled.img`
`;

const SearchWrapper = styled.div`
  flex: 1;
  max-width: 408px;
  z-index: 20003;
`;

const ContentWrapper = styled.div`
  .h__contactus {
    position: fixed;
    bottom: 47px;
    left: 47px;
    z-index: 2147483647;

    img {
      cursor: pointer;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 15px;
      width: 30px;
    }
  }

`;


const LangWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  z-index: 20001;
  margin-left: 30px;

  .h__util {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .lang {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 15px;
      position: relative;


      button {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        flex: 1;

        &:hover {
          color: ${props => props.theme.color.green_100};
          font-weight: bold;
        }
      }

      ul {
        position: absolute;
        z-index: 10000;
        top: 100%;
        right: 0;
        overflow-x: hidden;
        overflow-y: auto;
        width: 140px;
        max-width: 536px;
        margin-top: 10px;
        padding: 2px 0 4px 0;
        background: ${props => props.theme.color.site_main_color};
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid ${props => props.theme.color.default_border_color};
        border-radius: 6px;
      }

      li {
        display: flex;
        align-items: center;
        height: 30px;
        margin: 2px 4px 0;
        padding: 2px 5px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 6px;
        color: ${props => props.theme.color.dark_200};

        &:hover {
          background: ${props => props.theme.color.white_200};
          color: ${props => props.theme.color.dark_100};
        }

        &.selected {
          background-color: ${props => props.theme.color.green_200};
          color: ${props => props.theme.color.green_100};
          font-weight: bold;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 99999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: ${props => props.theme.color.site_main_color};
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 10%);
`;

const FlexWrapper = styled.div`
  flex: 1;
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  z-index: 20000;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 66px;
  margin-left: 50px;
`;

const HoverBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 100%;
  flex: 1;
  max-width: 150px;
  z-index: 999999;
`;
const NavText = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  flex: 1;
  max-width: 150px;
  margin-right: 20px;
  text-align: center;
  color: ${props => props.theme.color.dark_200};

  &:hover {
    opacity: 1;
    border-radius: 7px;
    color: ${props => props.theme.color.dark_100};
    font-weight: bold;
  }

  &.active {
    border-radius: 7px;
    color: ${props => props.theme.color.dark_100};
    font-weight: bold;
    opacity: 1;
  }`;


const NavColumnText = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  min-width: 150px;
  color: ${props => props.theme.color.dark_200};
  font-size: 14px;
  text-align: center;

  &:hover {
    color: ${props => props.theme.color.dark_100};
    text-decoration: underline;
  }
`;


const NavLinkWrapper = styled(NavText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NewTag = styled.span`
  position: absolute;
  top: -7px;
  right: -10px;
  background: transparent linear-gradient(112deg, #27E2C5 0%, #4B7CCE 100%) 0 0 no-repeat padding-box;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  font-weight: bold;
`;


const MaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1130px;
`;

const BetaText = styled.span`
  text-align: center;
  margin-top: 6px;
  width: 40px;
  padding-bottom: 1px;
  background: transparent linear-gradient(90deg, #2e79bb 0%, #561dab 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
`;

const IframeWrapper = styled.div`
`;

const HeaderBox = styled.div`
  max-width: 1920px;
  padding: 0 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  z-index: 1;
`;

const AbosluteBar = styled.div`
  width: 1px;
  height: 15px;
  background-color: ${props => props.theme.color.default_border_color};
`;

const LangButton = styled.button`
  color: ${props => {
    let color = props.theme.color.dark_100;
    if (props.hover) color = props.theme.color.green_100;
    if (props.open) color = props.theme.color.green_100;
    return color;
  }};

  font-weight: ${props => {
    let weight = 500;
    if (props.hover) weight = 'bold';
    if (props.open) weight = 'bold';
    return weight;
  }};
  @media only screen and (max-width: 1919px) {
    margin-right: 10px;
  }
`;

const LangBox = styled.div`
  @media only screen and (min-width: 1920px) {
    min-width: 118px;
  }
`;
const langArray = ['ENG', 'KOR', 'ja', 'es', 'pt', 'fr', 'de', 'pl', 'nl', 'it', 'fi', 'norway', 'tr', 'hu', 'ro', 'sr', 'ru', 'th', LANGUAGE_FOR_OS.VI, 'zh-CN', 'zh-TW'];
const Header = ({initLoadingNotFound, openView, setOpenView, isHome}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [view, setView] = useState(false);
    const region = useSelector((state) => state.region.result);
    const [openLang, setOpenLang] = useState(false);
    const currentLang = useSelector((state) => state.translation.lang);
    const [isTop, setIsTop] = useState(true);
    const router = useRouteMatch();
    const {pathname} = useLocation();
    useHistory();
    const langRef = useRef(null);
    const theme = useTheme();
    const [hoverMenu, setHoverMenu] = useState(false);
    const [hoverLang, setHoverLang] = useState(false);


    useEffect(() => {
        if (pathname.indexOf('/champions') === -1) {
            dispatch({
                type: PREV_RANKING_LANE,
                data: 'top',
            })
        }
    }, [pathname]);


    useEffect(() => {
        if (openView === true) {
            setView(openView);
            setOpenView(false);
        }
    }, [openView])


    const disaptchNotice = useCallback((data) => {
        dispatch({
            type: _NOTICE,
            data: data,
        })
    }, [])


    useEffect(() => {
        const fetchData = () => {
            axios.get(`${process.env.REACT_APP_CACHE_API}common/riot-status?platform_id=${getRegion(region)}`).then((res) => {
                if (!res.data.status) {
                    disaptchNotice('alertError');
                }
            }).catch((e) => {
                const statusCode = e?.response?.status;
                console.log(e);
                if (statusCode === 404) {

                } else if (statusCode === 500) {

                }
            });
        };
        fetchData();
    }, [region]);

    const onChangeLang = (lang) => {
        if (lang === 'KOR') {
            i18n.changeLanguage(LANGUAGE_FOR_OS.ko);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, LANGUAGE_FOR_OS.ko);
        } else if (lang === 'ENG') {
            i18n.changeLanguage(LANGUAGE_FOR_OS.EN);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, LANGUAGE_FOR_OS.EN);
        } else if (lang === 'vi') {
            i18n.changeLanguage(LANGUAGE_FOR_OS.VI);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, LANGUAGE_FOR_OS.VI);
        } else {
            i18n.changeLanguage(lang);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, lang);
        }
        dispatch(translation(lang));
        setOpenLang(false);
    };

    // 언어설정 토글
    const toggleLang = () => {
        setOpenLang((prev) => !prev);
    };

    // 문의하기 열기
    const handleContactOpen = () => {
        setView(true);
    };
    // 문의하기 닫기
    const handleContactClose = () => {
        setView(false);
    };

    // 언어설정 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (openLang && (!langRef.current || !langRef?.current?.contains(e.target))) setOpenLang(false);
        },
        [openLang]
    );

    const scrollEvent = useCallback(() => {
        if (document.scrollingElement.scrollTop === 0) {
            setIsTop(true);
        } else {
            if (isTop) {
                setIsTop(false);
            }
        }
    }, [isTop])

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', scrollEvent)
        };
    }, [handleClickOutside]);


    const championAramCommonActive = useCallback((pathname) => {
        return pathname.indexOf('summoner') === -1
    }, []);

    const isActiveChampions = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        const championResult = common && location.pathname.indexOf('/champions') > -1 && location.pathname.indexOf('/aram') === -1 && location.pathname.indexOf('/mastery') === -1 && location.pathname.indexOf('/urf') === -1;
        // const aramCommon = championAramCommonActive(location.pathname);
        // const aramResult = aramCommon && location.pathname.indexOf('/aram') > -1 && pathname.indexOf('daily') === -1;
        // return championResult || aramResult
        return championResult
    }, []);

    const isActiveAram = useCallback((match, location) => {
        const aramCommon = championAramCommonActive(location.pathname);
        const aramResult = aramCommon && location.pathname.indexOf('/aram') > -1 && pathname.indexOf('daily') === -1;
        return aramResult
    }, []);

    // const isActiveURF = useCallback((match, location) => {
    //     const common = championAramCommonActive(location.pathname);
    //     return common && location.pathname.indexOf('/urf') > -1
    // }, []);

    const isActiveMastery = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/mastery') > -1
    }, []);

    const isActiveRanking = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        const rankingResult = common && location.pathname.indexOf('/ranking') > -1
        const masteryResult = isActiveMastery(match, location)
        const dailyResult = isActiveDailyRanking(match, location)
        return rankingResult || masteryResult || dailyResult
    }, []);

    const isActiveDailyRanking = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/daily/ai') > -1
    }, []);

    const filteredRankRegion = useMemo(() => {
        const current = regionList.find((data) => data.title === region);
        if (current?.rank) {
            return current.title.toLowerCase()
        } else {
            return regionList[0].title.toLowerCase();
        }
    }, [region]);


    const handleMouseOver = () => {
        setHoverMenu(true)
    }

    const handleMouseOut = () => {
        setHoverMenu(false)
    }


    const handleLangMouseOver = () => {
        setHoverLang(true)
    }

    const handleLangMouseOut = () => {
        setHoverLang(false)
    }


    return (
        <>
            <Wrapper>
                <HeaderBox>

                    <LogoWrapper>
                        <Link to="/">
                            <LogoImg src={`${theme.icon.path}/ico_logo_w.png`} width={160} height={28}/>
                        </Link>
                    </LogoWrapper>

                    <CenterWrapper>
                        <MaxWrapper>
                            <FlexWrapper>
                                <NavWrapper>
                                    <NavText exact to="/">{t('header.navHome')}</NavText>
                                    {/*<NavText to="/summoner">{t('header.navSummoner')}</NavText>*/}
                                    <NavText to="/champions"
                                             isActive={isActiveChampions}>{t('header.navChampions')}</NavText>
                                    <NavText to="/aram"
                                             isActive={isActiveAram}>{t('header.navAram')}</NavText>
                                    {/*<MaintainTag><ScaleFont>점검중</ScaleFont></MaintainTag>*/}
                                    <HoverBox onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                        <NavText to={`/ranking/${filteredRankRegion}/all`}
                                                 isActive={isActiveRanking}>{t('header.navRanking')}</NavText>
                                        {hoverMenu && <SubHeader region={region}/>}
                                    </HoverBox>
                                    {/*<NavLinkWrapper to="/spectate/live/pro">*/}
                                    {/*    <span>{t('header.navLive')}</span>*/}
                                    {/*    <NewTag>NEW</NewTag>*/}
                                    {/*</NavLinkWrapper>*/}
                                    <NavText to="/multi">{t('header.navMulti')}</NavText>
                                </NavWrapper>
                            </FlexWrapper>
                            {/*<SubHeader region={region}/>*/}
                        </MaxWrapper>
                    </CenterWrapper>
                    <SearchWrapper>
                        {!isHome && <HeaderSearch initLoadingNotFound={initLoadingNotFound} key={router.url}/>}
                    </SearchWrapper>
                    <LangWrapper>
                        <div className="h__util">
                            <ProRequestSwitch/>
                            <ContentSwitch/>
                            <ThemeSwitch/>
                            <AbosluteBar/>
                            <LangBox className="lang" ref={langRef} onClick={toggleLang}
                                     onMouseOver={handleLangMouseOver}
                                     onMouseOut={handleLangMouseOut}>
                                <LangButton open={openLang}
                                            isHover={hoverLang}>{getOriginalNames(currentLang)}</LangButton>
                                {openLang ?
                                    <UpArrow size={4} color={theme.color.green_100}/>
                                    :
                                    <DownArrow size={4}
                                               color={hoverLang ? theme.color.green_100 : theme.color.dark_100}/>
                                }
                                {openLang && (
                                    <ul>
                                        {langArray.map((data) => {
                                            return (
                                                <li
                                                    key={data}
                                                    className={`${currentLang === data && 'selected'}`}
                                                    onClick={() => onChangeLang(data)}
                                                >
                                                    {getOriginalNames(data)}
                                                </li>
                                            )

                                        })}
                                        {/*<li*/}
                                        {/*    className={`${currentLang === 'KOR' && 'selected'}`}*/}
                                        {/*    onClick={() => onChangeLang('KOR')}*/}
                                        {/*>*/}
                                        {/*    한국어*/}
                                        {/*</li>*/}
                                        {/*<li*/}
                                        {/*    className={`${currentLang === 'ENG' && 'selected'}`}*/}
                                        {/*    onClick={() => onChangeLang('ENG')}*/}
                                        {/*>*/}
                                        {/*    English*/}
                                        {/*</li>*/}
                                        {/*<li*/}
                                        {/*    className={`${currentLang === 'vi' && 'selected'}`}*/}
                                        {/*    onClick={() => onChangeLang('vi')}*/}
                                        {/*>*/}
                                        {/*    Tiếng Việt*/}
                                        {/*</li>*/}
                                    </ul>
                                )}
                            </LangBox>
                        </div>
                    </LangWrapper>
                </HeaderBox>
            </Wrapper>
            {/*<ContentWrapper>*/}
            {/*    <div className="h__contactus">*/}
            {/*        {!view && (*/}
            {/*            <img*/}
            {/*                src="/images/ico_write_qa_w.svg"*/}
            {/*                alt="contact us open"*/}
            {/*                className="open"*/}
            {/*                onClick={handleContactOpen}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*        {view && (*/}
            {/*            <img*/}
            {/*                src="/images/ico_search_tag_del.svg"*/}
            {/*                alt="contact us close"*/}
            {/*                className="close"*/}
            {/*                onClick={handleContactClose}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*        {view && (*/}
            {/*            <IframeWrapper>*/}

            {/*                <iframe*/}
            {/*                    title="contact-us"*/}
            {/*                    src="https://2021.deeplol.gg/test__2.html"*/}
            {/*                    frameBorder="0"*/}
            {/*                    width={350}*/}
            {/*                    height={510}*/}
            {/*                />*/}
            {/*            </IframeWrapper>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</ContentWrapper>*/}
            {/*</HeaderContainer>*/}
        </>
    );
};


const SubHeaderWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  justify-content: center;
  flex: 1;
  background-color: ${props => props.theme.color.site_main_color};
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 10%);
`;
const EmptyLeft = styled.div`
  width: 175px;
`;
const EmptyRight = styled.div`
  width: 630px;
`;
const NavColumn = styled.div`
  width: 150px;
`;

const NavColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AbsoluteBox = styled.div`
  padding-right: 15px;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
`;
const SubHeader = ({region}) => {
    const {t} = useTranslation();


    const filteredRankRegion = useMemo(() => {
        const current = regionList.find((data) => data.title === region);
        if (current.rank) {
            return current.title.toLowerCase()
        } else {
            return regionList[0].title.toLowerCase();
        }
    }, [region]);

    const filteredRegion = useMemo(() => {
        const current = regionList.find((data) => data.title === region);
        if (current?.daily) {
            return current.title.toLowerCase()
        } else {
            return regionList[0].title.toLowerCase();
        }
    }, [region]);

    return (
        <AbsoluteBox>

            <SubHeaderWrapper>
                {/*<SearchWrapper>*/}
                {/*    {!isHome && <HeaderSearch initLoadingNotFound={initLoadingNotFound} key={router.url}/>}*/}
                {/*</SearchWrapper>*/}
                <NavColumnWrapper>
                    <NavColumn>
                        <NavColumnText
                            to={`/ranking/${filteredRankRegion}/all`}>{t('tabs.rankedSolo')}</NavColumnText>
                        <NavColumnText to="/mastery">{t('header.navMastery')}</NavColumnText>
                        <NavColumnText
                            to={`/daily/ai/${filteredRegion}/rank/all`}>{t('header.navDailyAi')}</NavColumnText>
                    </NavColumn>
                </NavColumnWrapper>
            </SubHeaderWrapper>
        </AbsoluteBox>
    );
};


export default Header;
