import React, {useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {bookmarkList} from '../../store/modules/bookmarkList';
import {ProfileContainer} from '../../styled/SummonerStyle';
import styled from "styled-components";
import InfluInfo from "./Profile/InfluInfo";
import ErrorBox from "../common/Error";
import SummonerDict from "../common/SummonerDict";
import SummonerUpdateButton from "./summonerUpdate/SummonerUpdateButton";
import ReletiveSummoner from "./ReletiveSummoner";

const Wrapper = styled.div`
  opacity: 1;
  display: flex;
  flex: 1;
  z-index: 9999;
`;

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const AdBox = styled.div`
  width: 410px;
  height: 230px;
`;


const EmptyInfluWrapper = styled.div`
  min-height: 22px;
`;
const InfluWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  align-content: center;
  flex-direction: row;
`;


const AbsoluteBox = styled.div`
  position: absolute;
  width: 300px;
  bottom: 100px;
  z-index: 999999;

  .errorStyle {
    z-index: 999999;
    padding: 14px 0;
  }
`;


const ProfileBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const TagText = styled.span`
  font-size: 20px;
  line-height: 22px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 400;
  margin-left: 5px;
`;

const AKAtext = styled.span`
  font-size: 12px;
  line-height: 17px;
`;

const EmptyText = styled.div`
  height: 17px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 20px 0 20px 20px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Profile = ({
                     region,
                     userName,
                     queueNum,
                     selectedSeason,
                 }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useRef(null);
    const version = useSelector((state) => state.gameVersion.version);
    // const [testValue, setTextValue] = useState(100);
    const [openError, setOpenError] = useState(false);
    const {summoner} = useSelector((state) => state?.summoner);


    const bookmarkslength = useSelector((state) => state?.bookmarkList?.bookmarks?.length);
    const bookmarks = useSelector((state) => {
        let arr = state?.bookmarkList.bookmarks.filter((item) => item?.summoner_basic_info_dict?.summoner_id === summoner?.summoner_basic_info_dict?.summoner_id);
        return arr.length > 0
    });

    // 즐겨찾기 버튼
    const toggleBookmark = () => {
        if (bookmarks) {
            dispatch(bookmarkList(summoner, 'remove'));
        } else {
            if (bookmarkslength > 11) {
                opencloseError()
            } else {
                dispatch(bookmarkList({...summoner, region: region}, 'add'));
            }

        }
    };

    const opencloseError = () => {
        setOpenError(true);
        setTimeout(() => {
            setOpenError(false);
        }, 2000);
    };


    const summonerBasic = useMemo(() => summoner?.summoner_basic_info_dict, [summoner]);


    return (
        <ProfileContainer>
            {Object.keys(summonerBasic).length > 0 &&
                <>
                    {/*<div className="p__bg">*/}
                    {/*    <SplashImage summonerStat={summonerStatus}/>*/}
                    {/*</div>*/}
                    <div className="p__tier">
                        <SummonerDict
                            dict={summonerBasic.previous_season_tier_list}
                        />
                    </div>
                    <Wrapper>
                        <div className="p__info">
                            <ProfileWrapper>

                                <ProfileBox>
                                    <div className="icon">
                                        <img
                                            src={`${process.env.REACT_APP_IMG}${version}/img/profileicon/${summonerBasic?.profile_id}.png?f=webp`}
                                            alt={summoner && summonerBasic?.profile_id}
                                            width={97}
                                            height={97}
                                            onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                                        />
                                        <p>{summonerBasic?.level}</p>
                                    </div>
                                    <div className="text">
                                        {/*{summoner?.pro_info.team !== '-' && (*/}
                                        {/*    <span className="pro">{summoner?.pro_info.team} {summoner?.pro_info?.name}</span>*/}
                                        {/*)}*/}
                                        <div className="name">
                                            <TextWrapper>
                                                <p>{decodeURI(summonerBasic?.riot_id_name)}</p>
                                                <TagText>#{summonerBasic?.riot_id_tag_line}</TagText>
                                            </TextWrapper>
                                            <div className="favorite">
                                                {openError &&
                                                    <AbsoluteBox>
                                                        <ErrorBox
                                                            infoText={t('error.favorite_excess')}
                                                        />
                                                    </AbsoluteBox>}
                                                <button className={`${bookmarks ? 'on' : 'off'}`}
                                                        onClick={toggleBookmark}>
                                                </button>
                                            </div>
                                        </div>
                                        {summonerBasic?.pro_streamer_info_dict?.status ?
                                            <InfluWrapper>
                                                <InfluInfo
                                                    highlight={true}
                                                    visibleHome={true}
                                                    influData={{
                                                        ...summonerBasic?.pro_streamer_info_dict,
                                                        team: summonerBasic?.pro_streamer_info_dict.pro_team_al
                                                    }}
                                                />
                                            </InfluWrapper>
                                            : <EmptyInfluWrapper/>
                                        }
                                        {/*{summoner?.challenges_dict?.title_id ?*/}
                                        {/*    <ErrorBoundary fallback={null}>*/}
                                        {/*        <ChallengeWrapper>*/}
                                        {/*            <Challenges*/}
                                        {/*                titleId={summoner?.challenges_dict?.title_id}*/}
                                        {/*                challengeList={summoner?.challenges_dict?.challenge_list}*/}
                                        {/*            />*/}
                                        {/*        </ChallengeWrapper>*/}
                                        {/*    </ErrorBoundary> : null*/}
                                        {/*}*/}
                                        <SummonerUpdateButton
                                            userName={userName}
                                            summoner={summoner}
                                            region={region}
                                            selectedSeason={selectedSeason}
                                            queueNum={queueNum}
                                        />
                                    </div>
                                </ProfileBox>
                            </ProfileWrapper>
                            <ReletiveSummoner
                                list={summonerBasic?.pro_streamer_info_dict?.friends_info_list}
                                krTeamName={summonerBasic?.pro_streamer_info_dict?.friends_kr}
                                enTeamName={summonerBasic?.pro_streamer_info_dict?.friends_en}
                                showTeamName={summonerBasic?.pro_streamer_info_dict?.show}/>
                        </div>
                    </Wrapper>
                </>
            }
        </ProfileContainer>
    );
};

export default React.memo(Profile);
