import React, {useCallback, useMemo} from 'react';
import JustRuneImage from "../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailRunes/JustRuneImage";
import DetailMapRune from "../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailRunes/DetailPrimaryRunes";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import runeShards from "../../../store/static_json/runeShards";
import runeShards_en from "../../../store/static_json/runeShards_en";
import useRuneFunc from "../../../common/hooks/useRuneFunc";
import {FlexBox} from "../../ui/styled";

const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
  padding-left: 12px;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  position: relative;
  height: 26px;
`;


const RuneContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.site_main_color};
`;
const RuneBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
`;
const MainRuneWrapper = styled.div`
  width: 166px;
  display: flex;
  flex-direction: column;
`;

const SubRuneWrapper = styled.div`
  width: 148px;
  display: flex;
  flex-direction: column;
`;

const SubRune = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${props => props.marginTop || 10}px;
  padding: ${props => props.padding || 0};
`;


const MainRuneInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom || 0}px;
`;

const MainRuneText = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  color: ${props => props.theme.color.dark_200};
`;

const MainRuneInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;
const DiamondWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin-top: 14px;
`;

const DiamondBar = styled.div`
  width: 95%;
  height: 1px;
  background-color: #CAD7E2;
`;
const AbsoluteDiamon = styled.div`
  position: absolute;
  left: ${props => props.left}px;
  right: ${props => props.right}px;
`;

const Diamond = styled.div`
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-bottom-color: #CAD7E2;
  position: relative;
  top: -4px;

  &:after {
    content: '';
    position: absolute;
    left: -4px;
    top: 4px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #CAD7E2;
  }
`;

const Wrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 13px 0;
`;

const JustRuneImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #000;
  width: 24px;
  height: 24px;

  opacity: ${props => {
    let opacity = '1';
    if (props.disalbed) opacity = '0.3';
    return opacity;
  }};

  & + & {
    margin-left: 5px;
  }
`;
const JustRuneImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ShardWrapper = styled.div`
  width: 138px;
`;

const Bar = styled.div`
  width: 1px;
  height: 243px;
  background-color: ${props => props.theme.color.default_border_color};
  margin: 0 20px;
`;

const FullRuneView = ({runeList, customRunes}) => {
    const {i18n, t} = useTranslation();
    const runes = useSelector((state) => state.imageStore.runes);
    const {getRuneBorderColor} = useRuneFunc();
    const currentRuneShards = useMemo(() => ['ko', 'ko-KR'].includes(i18n.language) ? runeShards : runeShards_en, [i18n.language]);
    const findCategoryRune = useCallback((runeData, findValue) => {
        return runeData.find((data) => data.id === findValue);
    }, []);

    const findChildRune = useCallback((categoryRune = [], findValue) => {
        return categoryRune.find((data) => {
            if (data.id === findValue) {
                return true;
            } else if (Array.isArray(data.runes)) {
                return findCategoryRune(data.runes, findValue);
            }
        });
    }, [findCategoryRune]);


    const findParentRune = useCallback((categoryRune = [], findValue) => {
        try {
            if (typeof findValue === 'number') {
                return categoryRune.find((data) => {
                    if (data.id === findValue) {
                        return true;
                    } else {
                        return findChildRune(data.slots, findValue);
                    }
                });
            }
            return null;
        } catch (e) {
            return [];
        }

    }, [findChildRune]);

    const selectedRune = useMemo(() => findParentRune(runes, runeList?.rune_main?.[0]), [runes, runeList])
    const subSelectedRune = useMemo(() => findParentRune(runes, runeList?.rune_sub?.[0]), [runes, runeList])

    if (!Array.isArray(runeList?.rune_main) || runeList?.rune_main?.length < 1) return (
        <Wrapper>
            <HeaderBox>{t('summoner.mastery.rune')}</HeaderBox>
        </Wrapper>);

    return (
        <Wrapper className={'summoner_mastery_wrapper'}>
            <RuneContent>
                <RuneBox>
                    <MainRuneWrapper>
                        <MainRuneInfo>
                            <MainRuneInfoBox marginBottom={0}>
                                {/*<JustRuneImage*/}
                                {/*    width={24}*/}
                                {/*    height={24}*/}
                                {/*    image={findParentRune(runes, runeList?.rune_main?.[0])?.icon}*/}
                                {/*/>*/}
                                <MainRuneText>{findParentRune(runes, runeList?.rune_main?.[0])?.name}</MainRuneText>

                                <JustRuneImageWrapper>
                                    {runes.map((data) => {
                                        return (
                                            <JustRuneImageBox disalbed={selectedRune.id !== data.id} key={data.id}>
                                                <JustRuneImage image={data.icon} height={18} width={18}/>
                                            </JustRuneImageBox>)
                                    })}
                                </JustRuneImageWrapper>
                            </MainRuneInfoBox>
                        </MainRuneInfo>
                        {findParentRune(runes, runeList?.rune_main?.[0])?.slots?.map((data, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <SubRune marginTop={10} padding={index !== 0 ? '0 9px' : 0}>
                                        <DetailMapRune
                                            width={index === 0 ? 42 : 36}
                                            height={index === 0 ? 42 : 36}
                                            selectedRune={[...runeList.rune_main]}
                                            imageBorder={2}
                                            borderColor={getRuneBorderColor(selectedRune.id)}
                                            runes={data.runes}
                                        />
                                    </SubRune>
                                </React.Fragment>
                            )
                        })}
                    </MainRuneWrapper>
                    <Bar/>
                    <SubRuneWrapper>
                        <MainRuneInfo>
                            <MainRuneInfoBox marginBottom={0}>
                                {/*<JustRuneImage*/}
                                {/*    width={24}*/}
                                {/*    height={24}*/}
                                {/*    image={findParentRune(runes, runeList?.rune_sub?.[0])?.icon}*/}
                                {/*/>*/}
                                <MainRuneText>{findParentRune(runes, runeList?.rune_sub?.[0])?.name}</MainRuneText>
                                <JustRuneImageWrapper>
                                    {runes.map((data) => {
                                        if (selectedRune.id === data.id) return null;
                                        return (
                                            <JustRuneImageBox disalbed={subSelectedRune.id !== data.id} key={data.id}>
                                                <JustRuneImage image={data.icon} height={18} width={18}/>
                                            </JustRuneImageBox>)
                                    })}
                                </JustRuneImageWrapper>
                            </MainRuneInfoBox>
                        </MainRuneInfo>
                        {findParentRune(runes, runeList?.rune_sub?.[0])?.slots?.map((data, index) => {
                            if (index === 0) return null
                            return (
                                <SubRune key={index}>
                                    <DetailMapRune

                                        width={36}
                                        height={36}
                                        selectedRune={[...runeList.rune_sub]}
                                        imageBorder={2}
                                        borderColor={getRuneBorderColor(subSelectedRune.id)}
                                        runes={data.runes}
                                    />
                                </SubRune>
                            )
                        })}
                    </SubRuneWrapper>
                    <Bar/>
                    <ShardWrapper>
                        <FlexBox height={'auto'} justifyContent={'center'}>
                            <MainRuneText>룬 파편</MainRuneText>
                        </FlexBox>
                        {currentRuneShards?.slots?.map((data, index) => {
                            const selectedRuneData = () => {
                                if (index === 0) {
                                    return [runeList.rune_stat?.[2]];
                                } else if (index === 1) {
                                    return [runeList.rune_stat?.[1]]
                                } else if (index === 2) {
                                    return [runeList.rune_stat?.[0]]
                                }
                            }
                            return (
                                <SubRune key={index}>
                                    <DetailMapRune
                                        width={24}
                                        height={24}
                                        selectedRune={selectedRuneData()}
                                        imageBorder={2}
                                        borderColor={"#46B9AA"}
                                        runes={data.runes}
                                    />
                                </SubRune>
                            )
                        })}

                    </ShardWrapper>
                </RuneBox>
            </RuneContent>
        </Wrapper>
    );
};

export default FullRuneView;